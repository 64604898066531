import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";

export default function Vorstand() {
  return (
    <Layout pageTitle={`Vorstand`}>
      <div className="vorstand site-content_inner">
        <h2>Vorstand</h2>
        <p>
          Der Vorstand der „Gesell&shy;schaft der Freunde und Förde&shy;rer des
          Städtischen Luisen-Gymna&shy;siums zu Düssel&shy;dorf e.V.“ besteht
          aus{" "}
          <strong>
            vier gewählten Mit&shy;glie&shy;dern und der Schul&shy;leiterin
          </strong>{" "}
          als erste stell&shy;vertre&shy;tende Vor&shy;sitzende.
        </p>
        <p>
          Auf der <strong>Jahres&shy;hauptver&shy;sammlung</strong>, zu der alle
          Mit&shy;glieder des Förder&shy;vereins schrift&shy;lich
          einge&shy;laden werden, findet die Wahl der Vorstands&shy;mitglieder
          für eine Amts&shy;zeit von jeweils zwei Jahren statt, eine (auch
          mehrmalige) Wieder&shy;wahl ist möglich.
        </p>
        <StaticImage
          src="../images/vorstand.jpg"
          alt="Luisen Freunde - Vorstand"
        />
        <span className="caption">
          v.l.n.r. Axel&nbsp;Berns, Esra&nbsp;Sentürk, Martin&nbsp;Heil,
          Gabriele&nbsp;Patten
        </span>
        <div className="board-members">
          <div className="board-members_left">
            <div className="board-member">
              <div className="board-member_header">
                <p className="label">Vorsitzender</p>
                <p className="name">Martin Heil</p>
              </div>
              <div className="board-member_content">
                <p>
                  Ich bin seit 2017 als Schrift&shy;führer und seit 2019 als
                  Vorsitzen&shy;der im Vor&shy;stand. Unser Sohn David geht seit
                  2016 auf das Luisen-Gymnasium. Wir waren von der
                  Atmos&shy;phäre am Luisen schon bei unserem ersten Besuch des
                  Schul&shy;festes ein Jahr vor Davids Wechsel aufs
                  Gym&shy;nasium begeistert. Damals gab es „Wir leben
                  Viel&shy;falt.“ als offiziel&shy;les Motto noch nicht, aber
                  genau das haben wir dort wahr&shy;genommen. Deshalb
                  enga&shy;giere ich mich gerne an unserer Schule.
                </p>
              </div>
            </div>
            <div className="board-member">
              <div className="board-member_header">
                <p className="label">Schatzmeister</p>
                <p className="name">Axel Berns</p>
              </div>
              <div className="board-member_content">
                <p>
                  Ich bin Schatz&shy;­meister des Förder­&shy;ver&shy;eins seit
                  Früh&shy;jahr 2016. Mein Sohn Moritz ist seit 2014 auf dem
                  Luisen-Gymnaisum. Die Über­&shy;nahme eines Ehren­&shy;amtes
                  im Förder&shy;­verein erlaubt mir die Schule bei
                  ver­&shy;schie&shy;denen Be­&shy;langen direkt zu
                  unter&shy;­stützen und meine Berufs­&shy;erfahrung
                  weiter­&shy;zu&shy;geben. Der Schul&shy;­ge­&shy;mein­schaft
                  zu helfen macht mir Spaß.
                </p>
              </div>
            </div>
          </div>
          <div className="board-members_right">
            <div className="board-member">
              <div className="board-member_header">
                <p className="label">Erste stellvertretende Vorsitzende</p>
                <p className="name">Gabriele Patten</p>
              </div>
              <div className="board-member_content">
                <p>
                  Ich bin seit 2006 Lehrerin für Bio&shy;logie, Erd&shy;kunde
                  und Musik am Luisen-&shy;Gymnasium. Als Schul&shy;leiterin
                  (seit Sommer 2017) bin ich als „ge&shy;borenes Mit&shy;glied“
                  die erste stell&shy;ver&shy;tretende Vor&shy;sitzende des
                  Förder&shy;vereins. Die Arbeit des Förder&shy;vereins
                  er&shy;möglicht es an vielen Stellen, Schüler*innen und unsere
                  schulische Arbeit mit ihnen zu unter&shy;stützen und Dinge
                  an&shy;zu&shy;schieben, die aufgrund der be&shy;grenz&shy;ten
                  Haus&shy;halts&shy;lage der Schule ohne den Förder&shy;verein
                  einfach nicht möglich wären. Deshalb freue ich mich über das
                  Engage&shy;ment vieler Eltern, Ehe&shy;maligen und
                  Lehrer*innen unserer Schule.
                </p>
              </div>
            </div>
            <div className="board-member">
              <div className="board-member_header">
                <p className="label">Zweite stellvertretende Vorsitzende</p>
                <p className="name">Esra Sentürk</p>
              </div>
              <div className="board-member_content">
                <p>
                  Ich bin seit Sommer 2017 mit im Vor­&shy;stand des
                  Förder&shy;­vereins tätig. Meine Kinder (Amira, Esad und
                  Yusra) gehen seit 2015, 2018 und 2019 auf das
                  Luisen-Gymnasium. Mit meiner Tätig­&shy;keit im Vor­&shy;stand
                  des Förder­&shy;ver&shy;eins und in der
                  Eltern­&shy;pfleg­&shy;schaft versuche ich, die Schule
                  mit­&shy;zu&shy;g­e&shy;stal&shy;ten und dazu
                  bei­&shy;zu­&shy;tra&shy;gen, den schu&shy;lischen All&shy;tag
                  zu ver­&shy;bessern.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
